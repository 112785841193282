.predictive-search {
  display: none;
  position: absolute;
  top: calc(100% + 0.1rem);
  left: -0.1rem;
  border-width: var(--popup-border-width);
  border-style: solid;
  border-color: rgba(var(--color-foreground), var(--popup-border-opacity));
  background-color: rgb(var(--color-background));
  z-index: 3;
  border-bottom-right-radius: var(--popup-corner-radius);
  border-bottom-left-radius: var(--popup-corner-radius);
  box-shadow: var(--popup-shadow-horizontal-offset) var(--popup-shadow-vertical-offset) var(--popup-shadow-blur-radius) rgba(var(--color-shadow), var(--popup-shadow-opacity));
}

.predictive-search--search-template {
  z-index: 2;
  width: calc(100% + 0.2rem);
}

@media screen and (max-width: 749px) {
  .predictive-search--header {
    right: 0;
    left: 0;
    top: 100%;
  }
}

@media screen and (max-width: 989px) {
  .predictive-search {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

  }
}

@media screen and (min-width: 750px) {
  .predictive-search {
    border-top: none;
    width: calc(100% + 0.2rem);
  }

  .header predictive-search {
    position: relative;
  }
}

predictive-search[open] .predictive-search,
predictive-search[loading] .predictive-search {
  display: block;
}

.predictive-search__heading {
  border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
  margin: 0 auto;
  padding: 1.5rem 0 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 4rem);
  color: rgba(var(--color-foreground), 0.7);
}

predictive-search .spinner {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 0;
}

.predictive-search__heading .spinner {
  margin: 0 0.2rem 0 2rem;
}

predictive-search:not([loading]) .predictive-search__heading .spinner,
predictive-search:not([loading]) .predictive-search__loading-state,
predictive-search:not([loading]) .predictive-search-status__loading {
  display: none;
}

predictive-search[loading] .predictive-search__loading-state {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

predictive-search[loading] .predictive-search__heading ~ .predictive-search__loading-state,
predictive-search[loading] .predictive-search__results-list:first-child {
  display: none;
}

.predictive-search__list-item:nth-last-child(2) {
  border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
}

.predictive-search__list-item[aria-selected="true"] > *,
.predictive-search__list-item:hover > * {
  color: rgb(var(--color-foreground));
  background-color: rgba(var(--color-foreground), 0.04);
}

.predictive-search__list-item[aria-selected="true"] .predictive-search__item-heading,
.predictive-search__list-item:hover .predictive-search__item-heading {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.predictive-search__item {
  display: flex;
  padding: 1rem 2rem;
  text-align: left;
  text-decoration: none;
  width: 100%;
}

.predictive-search__item--link {
  display: grid;
  grid-template-columns: 5rem 1fr;
  grid-column-gap: 2rem;
  grid-template-areas: 'product-image product-content';
}

.predictive-search__item-content {
  grid-area: product-content;
  display: flex;
  flex-direction: column;
}

.predictive-search__item-content--centered {
  justify-content: center;
}

.predictive-search__item-vendor {
  font-size: 0.9rem;
}

.predictive-search__item-heading {
  margin: 0;
}

.predictive-search__item .price {
  color: rgba(var(--color-foreground), 0.7);
  font-size: 1.2rem;
}

.predictive-search__item-vendor + .predictive-search__item-heading,
.predictive-search .price {
  margin-top: 0.5rem;
}

.predictive-search__item--term {
  justify-content: space-between;
  align-items: center;
  padding: 1.3rem 2rem;
  word-break: break-all;
  line-height: calc(1 + 0.4 / var(--font-body-scale));
}

@media screen and (min-width: 750px) {
  .predictive-search__item--term {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.predictive-search__item--term .icon-arrow {
  width: calc(var(--font-heading-scale) * 1.4rem);
  height: calc(var(--font-heading-scale) * 1.4rem);
  flex-shrink: 0;
  margin-left: calc(var(--font-heading-scale) * 2rem);
  color: rgb(var(--color-link));
}

.predictive-search__image {
  grid-area: product-image;
  object-fit: contain;
  font-family: 'object-fit: contain';
}
